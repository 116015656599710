<template>
  <div id="simple-calendar-app">
    <div class="vx-card" ref="calendarEl">
      <vs-button class="mb-4" v-if="user_role==='admin'" icon-pack="feather" icon="icon-plus" @click="promptAddNewEvent(new Date(Date.now()))">{{ $t("Ajouter") }}</vs-button>
      <div class="vx-row" v-if="user_role!=='admin'">
          
          <div class="vx-col w-2/12">
              <vs-button class="mb-4" v-if="user_role!=='admin'" icon-pack="feather" icon="icon-plus" @click="promptAddNewEvent(new Date(Date.now()))">{{ $t("Ajouter") }}</vs-button>
          </div>
          <div class="vx-col w-10/12 flex">
          <!-- Labels -->
          <div class="flex flex-wrap justify-center">
              <div class="flex items-center mr-4 mb-8">
                  <div class="h-3 w-3 inline-block rounded-full mr-2  ml-4 bg-primary"></div>
                  <span>Normal</span>
                  <div class="h-3 w-3 inline-block rounded-full mr-2 ml-4 bg-danger"></div>
                  <span>Urgent</span> 
                  <div class="h-3 w-3 inline-block rounded-full mr-2 ml-4 bg-success"></div>
                  <span>Valider</span> 
                  
              </div>
          </div>
        </div>
          <!-- <div class="vx-col w-3/12">
            <v-select 
                :options="appointmentChoises" 
                placeholder="Filtre des rendez-vous"
                :reduce="rep => rep.key" 
                :clearable="true" 
                label="name"
                v-model="filter_appointment" 
                class="w-full"/>
          </div> -->

        </div>
      <FullCalendar
        class="demo-app-calendar"
        :options="calendarOptions"
      >
       
      </FullCalendar>
    </div>

    <!-- ADD EVENT -->
    <vs-popup v-if="user_role==='admin'"
        class="calendar-event-dialog"
        :title="this.$t('titre_Ajouter_rendevous')"
        :active.sync="activePromptAddEvent">

        <vs-checkbox v-model="checkbox_patient" class="mb-3">
          Est ce un patient de l'hopital?
        </vs-checkbox>
        <div>
        <small class="date-label">{{ $t("etat") }}</small>
        <v-select
            v-validate="'required'"
            name="etat"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full mt-2 " 
            v-model="urgent"
            :options="urgent_type">
          </v-select>
          <span class="text-danger text-sm pt-2" v-show="errors.has('etat')">{{$t("etat_error")}}</span>
        </div>
        <div  class=" pt-2">
            <small class="date-label ">{{ $t("appointementDate") }}</small>
            <flat-pickr 
             class="w-full pt-2"
             :config="testcaebdar" 
             v-validate="'required'"
             name="appointement"
             v-model="start" 
             placeholder="Date et heure" />
             
        </div>
        <span class="text-danger text-sm pt-2" v-show="errors.has('appointement')">{{$t("appointement_error")}}</span>
        <span class="text-danger text-sm pt-2" v-if="heure_check">{{$t("appointement_hours")}}</span>

        
        <p class="pt-2"> {{ $t("doctor") }}</p>
        <vs-select
              v-model="doctor"
              v-validate="'required'"
              autocomplete
              clearable
              class="w-full mb-4"
              name="doctor"
          >
          <vs-select-item :key="item.id" :value="item.id" :text="item.last_name" v-for="item in dentists" /></vs-select>
          
        <span  class="text-danger text-sm" v-show="errors.has('doctor')">{{$t("doctor_error")}}</span>

        <p class="pt-2" v-if="!checkbox_patient" >{{ $t("Nom") }}</p>
        <vs-input v-if="!checkbox_patient"
              name="last_name" 
              v-validate="'required'"
              class="w-full" 
              v-model="last_name">
        </vs-input>
        <span v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('last_name')">{{$t("last_name_error")}}</span>

        <p class="pt-2" v-if="!checkbox_patient" >{{ $t("Prénoms") }}</p>
        <vs-input v-if="!checkbox_patient"
              name="first_name" 
              v-validate="'required'"
              class="w-full" 
              v-model="first_name">
        </vs-input>
        <span v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('first_name')">{{$t("first_name_error")}}</span>
        
        <p class="pt-2" v-if="!checkbox_patient" >{{ $t("age") }}</p>
          <vs-input-number
                    v-if="!checkbox_patient"
                    :min="0" 
                    :max="120"
                    v-validate="'required'" 
                    name="age"
                    data-vv-validate-on="blur"
                    v-model="age" 
                    class="w-full"
                    />
       
          <span v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('age')">{{$t("age_error")}}</span>

          <p class="pt-2" v-if="checkbox_patient" >{{ $t("patient") }}</p>
          <vs-select
              v-if="checkbox_patient"
              v-model="patient"
              v-validate="'required'"
              autocomplete
              clearable
              class="w-full mb-4 "
              name="patient"
          >
          <vs-select-item :key="item.id" :value="item.id" :text="item.last_name" v-for="item in patients" />
          </vs-select>
          <span v-if="checkbox_patient" class="text-danger text-sm" v-show="errors.has('patient')">{{$t("patient_error")}}</span>

          <p class="pt-2" v-if="!checkbox_patient" >{{ $t("gender") }}</p>
          
          <v-select
           v-if="!checkbox_patient"
            v-validate="'required'"
            name="gender"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full " 
            v-model="gender"
            :options="genders">
          </v-select>
          <span v-if="!checkbox_patient" class="text-danger text-sm mb-4" v-show="errors.has('gender')">{{$t("gender_error")}}</span>
         
          <p class="pt-2" v-if="!checkbox_patient"> {{$t("Phone")}} </p>
          <vue-tel-input
              v-if="!checkbox_patient"
              v-model="phoneNumber"
              :enabledCountryCode="true"
              :enabledFlags="true"
              :validCharactersOnly="true"
              data-vv-validate-on="blur"
              v-validate="'required|min:8|max:25'"
              @validate="yourValidationMethod"
              name="phoneNumber"
              class="w-full mb-6"
          >
          </vue-tel-input>
          <span  v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{$t("phoneNumber_error")}}</span>
        
          <vs-textarea
          v-model="pattern"
           class="w-full pt-4" 
           rows="3"
           v-validate="'required'"
           name="pattern"
           label="Motif"  />
          <span class="text-danger text-sm" v-show="errors.has('pattern')">{{$t("pattern_error")}}</span>


         <div class="flex flex-wrap justify-between my-5">
          
          <vs-button v-if="checkbox_patient === false" :disabled="!validFormAdd2" @click="addEventCheckDate(new Date(Date.now()))" color="primary" type="filled">Ajouter </vs-button>
          
          <vs-button v-if="checkbox_patient === true" :disabled="!validFormAdd" @click="addEventCheckDate(new Date(Date.now()))" color="primary" type="filled">Ajouter </vs-button>

          <vs-button @click="CancelEvent" color="danger" type="filled">Annuler</vs-button>
        
        </div>
    </vs-popup>
    <vs-popup v-if="user_role==='admin'"
        class="calendar-event-dialog"
        :title="this.$t('titre_Ajouter_rendevous')"
        :active.sync="activePromptAddEventClickdate">

        <vs-checkbox v-model="checkbox_patient" class="mb-3">
          Est ce un patient de l'hopital?
        </vs-checkbox>
        <div>
        <small class="date-label">{{ $t("etat") }}</small>
        <v-select
            v-validate="'required'"
            name="etat"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full mt-2 " 
            v-model="urgent"
            :options="urgent_type">
          </v-select>
          <span class="text-danger text-sm pt-2" v-show="errors.has('etat')">{{$t("etat_error")}}</span>
        </div>
        <div  class=" pt-2">
            <small class="date-label ">{{ $t("appointementDate") }}</small>
            <flat-pickr 
             class="w-full pt-2"
             :config="testcaebdar" 
             v-validate="'required'"
             name="appointement"
             v-model="start" 
             placeholder="Date et heure" />
             
        </div>
        <span class="text-danger text-sm pt-2" v-show="errors.has('appointement')">{{$t("appointement_error")}}</span>
        <span class="text-danger text-sm pt-2" v-if="heure_check">{{$t("appointement_hours")}}</span>

        
        <p class="pt-2"> {{ $t("doctor") }}</p>
        <vs-select
              v-model="doctor"
              v-validate="'required'"
              autocomplete
              clearable
              class="w-full mb-4"
              name="doctor"
          >
          <vs-select-item :key="item.id" :value="item.id" :text="item.last_name" v-for="item in dentists" /></vs-select>
          
        <span  class="text-danger text-sm" v-show="errors.has('doctor')">{{$t("doctor_error")}}</span>

        <p class="pt-2" v-if="!checkbox_patient" >{{ $t("Nom") }}</p>
        <vs-input v-if="!checkbox_patient"
              name="last_name" 
              v-validate="'required'"
              class="w-full" 
              v-model="last_name">
        </vs-input>
        <span v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('last_name')">{{$t("last_name_error")}}</span>

        <p class="pt-2" v-if="!checkbox_patient" >{{ $t("Prénoms") }}</p>
        <vs-input v-if="!checkbox_patient"
              name="first_name" 
              v-validate="'required'"
              class="w-full" 
              v-model="first_name">
        </vs-input>
        <span v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('first_name')">{{$t("first_name_error")}}</span>
        
        <p class="pt-2" v-if="!checkbox_patient" >{{ $t("age") }}</p>
          <vs-input-number
                    v-if="!checkbox_patient"
                    :min="0" 
                    :max="120"
                    v-validate="'required'" 
                    name="age"
                    data-vv-validate-on="blur"
                    v-model="age" 
                    class="w-full"
                    />
       
          <span v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('age')">{{$t("age_error")}}</span>

          <p class="pt-2" v-if="checkbox_patient" >{{ $t("patient") }}</p>
          <vs-select
              v-if="checkbox_patient"
              v-model="patient"
              v-validate="'required'"
              autocomplete
              clearable
              class="w-full mb-4 "
              name="patient"
          >
          <vs-select-item :key="item.id" :value="item.id" :text="item.last_name" v-for="item in patients" />
          </vs-select>
          <span v-if="checkbox_patient" class="text-danger text-sm" v-show="errors.has('patient')">{{$t("patient_error")}}</span>

          <p class="pt-2" v-if="!checkbox_patient" >{{ $t("gender") }}</p>
          
          <v-select
           v-if="!checkbox_patient"
            v-validate="'required'"
            name="gender"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full " 
            v-model="gender"
            :options="genders">
          </v-select>
          <span v-if="!checkbox_patient" class="text-danger text-sm mb-4" v-show="errors.has('gender')">{{$t("gender_error")}}</span>
         
          <p class="pt-2" v-if="!checkbox_patient"> {{$t("Phone")}} </p>
          <vue-tel-input
              v-if="!checkbox_patient"
              v-model="phoneNumber"
              :enabledCountryCode="true"
              :enabledFlags="true"
              :validCharactersOnly="true"
              data-vv-validate-on="blur"
              v-validate="'required|min:8|max:25'"
              @validate="yourValidationMethod"
              name="phoneNumber"
              class="w-full mb-6"
          >
          </vue-tel-input>
          <span  v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{$t("phoneNumber_error")}}</span>
        
          <vs-textarea
          v-model="pattern"
           class="w-full pt-4" 
           rows="3"
           v-validate="'required'"
           name="pattern"
           label="Motif"  />
          <span class="text-danger text-sm" v-show="errors.has('pattern')">{{$t("pattern_error")}}</span>


         <div class="flex flex-wrap justify-between my-5">
          
          <vs-button v-if="checkbox_patient === false" :disabled="!validFormAdd2" @click="addEventCheckDate(new Date(Date.now()))" color="primary" type="filled">Ajouter </vs-button>
          
          <vs-button v-if="checkbox_patient === true" :disabled="!validFormAdd" @click="addEventCheckDate(new Date(Date.now()))" color="primary" type="filled">Ajouter </vs-button>

          <vs-button @click="CancelEvent" color="danger" type="filled">Annuler</vs-button>
        
        </div>
    </vs-popup>
    <vs-popup v-if="user_role==='admin'"
        class="calendar-event-dialog"
        :title="this.$t('titre_modifier_supprimer_redevous')"
        button-cancel = "border"
        :active.sync="activePromptEditEvent">

        <vs-checkbox disabled v-if="validated===true" v-model="validated" class="mb-3">
          Rendez-vous validé
        </vs-checkbox>
        <small class="date-label">{{ $t("etat") }}</small>
        <div>
        <v-select
            :disabled ="validated"
            v-validate="'required'"
            name="etat"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full mt-2 " 
            v-model="urgent"
            :options="urgent_type">
          </v-select>
          <span class="text-danger text-sm pt-2" v-show="errors.has('etat')">{{$t("etat_error")}}</span>
        </div>

        <small class="date-label">{{ $t("full_name") }}{{ " "}}{{'du patient'}}</small>
        <vs-input disabled name="event-name" v-validate="'required'" class="w-full"  v-model="title"></vs-input>
        
        <small class="date-label">{{$t("Phone")}}</small>
          <vue-tel-input
              disabled
              v-model="phoneNumber"
              :enabledCountryCode="true"
              :enabledFlags="true"
              :validCharactersOnly="true"
              data-vv-validate-on="blur"
              v-validate="'required|min:8|max:25'"
              @validate="yourValidationMethod"
              name="phoneNumber"
              class="w-full"
          >
          </vue-tel-input>
        
        <small class="date-label">{{ $t("doctor") }}</small>
        <vs-select
              v-model="doctor"
              disabled
              class="w-full mb-4"
              name="doctor"
          >
          <vs-select-item :key="itemid" :value="itemid.id" :text="itemid.last_name" v-for="itemid in dentists" /></vs-select>
          
        
        <div  class="my-4">
            <small v-if="((new Date(this.time_GMT))<(new Date(this.start)))" class="date-label">{{ $t("appointementDate") }}</small>
            <flat-pickr v-if="((new Date(this.time_GMT))<(new Date(this.start)))"
             class="w-full pt-2"
             :config="testcaebdar" 
             v-validate="'required'"
             name="appointementDate"
             v-model="start" 
            placeholder="Date et heure" />
        </div>
        <span v-if="((new Date(this.time_GMT))<(new Date(this.start)))" class="text-danger text-sm pt-2" v-show="errors.has('appointementDate')">{{$t("appointement_error")}}</span>
        <span class="text-danger text-sm pt-2" v-if="heure_check">{{$t("appointement_hours")}}</span>

        
        <small v-if="((new Date(this.time_GMT))>(new Date(this.start)))" class="date-label">{{ $t("appointementDate") }}</small>
        <vs-input v-if="((new Date(this.time_GMT))>(new Date(this.start)))" 
        disabled name="event-name" 
        v-validate="'required'"
        class="w-full mb-5"
        v-model="dateCheck2">
        </vs-input>
        
        
        <vs-textarea
          :disabled="((new Date(this.time_GMT))>(new Date(this.start)))"
          v-model="pattern"
           class="w-full " 
           rows="3"
           name="pattern"
           label="Motif"  />
<!--         <span class="text-danger text-sm" v-show="errors.has('pattern')">{{$t("pattern_error")}}</span>
 -->        
        <div class="flex flex-wrap justify-center mb-3">
          <vs-button v-if="validated === false" class="ml-6" :disabled="!validForm" @click="validate_appointment(new Date(Date.now()))" color="success" type="filled">Valider </vs-button>
          
          <vs-button class="ml-6" :disabled="!validForm" @click="editEvent" color="primary" type="filled">Modifier </vs-button>

          <vs-button class="ml-6" :disabled="!validForm" @click="removeEvent" color="danger" type="filled">Supprimer</vs-button>
        </div>
    </vs-popup>

    <vs-popup v-if="user_role==='admin'"
         class="calendar-event-dialog"
        :title="this.$t('detail_rendez_vous')"
         button-cancel = "border"
        :active.sync="activePromptEditEventAdmin">
        <vs-checkbox disabled v-if="validated===true" v-model="validated" class="mb-3">
          Rendez-vous validé
        </vs-checkbox>
        <vs-checkbox disabled v-if="validated===false" v-model="validated" class="mb-3">
          Rendez-vous non validé
        </vs-checkbox>
        <small class="date-label">{{ $t("etat") }}</small>
        <v-select
            disabled
            v-validate="'required'"
            name="etat"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full mt-2 " 
            v-model="urgent"
            :options="urgent_type">
          </v-select>
        <small class="date-label">{{ $t("full_name") }}{{ " "}}{{'du patient'}}</small>
        <vs-input disabled name="event-name" v-validate="'required'" class="w-full"  v-model="title"></vs-input>
        
        <small class="date-label">{{$t("Phone")}}</small>
          <vue-tel-input
              disabled
              v-model="phoneNumber"
              :enabledCountryCode="true"
              :enabledFlags="true"
              :validCharactersOnly="true"
              data-vv-validate-on="blur"
              v-validate="'required|min:8|max:25'"
              @validate="yourValidationMethod"
              name="phoneNumber"
              class="w-full"
          >
          </vue-tel-input>
        
        
        <small class="date-label">{{ $t("doctor") }}</small>
        <vs-select
              v-model="doctor"
              disabled
              class="w-full mb-4"
              name="doctor"
          >
          <vs-select-item :key="itemid" :value="itemid.id" :text="itemid.last_name" v-for="itemid in dentists" /></vs-select>
          
        <small class="date-label">{{ $t("appointementDate") }}</small>
        <vs-input
        disabled 
        name="event-name" 
        v-validate="'required'"
        class="w-full mb-5"
        v-model="dateCheck2">
        </vs-input>
        
        
        <vs-textarea
           disabled
          v-model="pattern"
           class="w-full " 
           rows="3"
           v-validate="'required'"
           name="pattern"
           label="Motif"  />
        <span class="text-danger text-sm" v-show="errors.has('pattern')">{{$t("pattern_error")}}</span>

    </vs-popup>

    <vs-popup v-if="user_role!=='admin'"
        class="calendar-event-dialog"
        :title="this.$t('titre_Ajouter_rendevous')"
        :active.sync="activePromptAddEvent">


        <vs-checkbox v-model="checkbox_patient" class="mb-3">
          Est ce un patient de l'hopital?
        </vs-checkbox>

        <small class="date-label">{{ $t("etat") }}</small>
        <v-select
            v-validate="'required'"
            name="etat"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full mt-2 " 
            v-model="urgent"
            :options="urgent_type">
          </v-select>
          <span class="text-danger text-sm pt-2" v-show="errors.has('etat')">{{$t("etat_error")}}</span>
  
        <div>
            <small class="date-label">{{ $t("appointementDate") }}</small>
            <flat-pickr 
             class="w-full pt-2"
             :config="testcaebdar" 
             v-validate="'required'"
             name="appointement"
             v-model="start" 
             placeholder="Date et heure" />
             
        </div>
        <span class="text-danger text-sm pt-2" v-show="errors.has('appointement')">{{$t("appointement_error")}}</span>
        <span class="text-danger text-sm pt-2" v-if="heure_check">{{$t("appointement_hours")}}</span>

        <p class="pt-2" v-if="user_role ==='secretary'"> {{ $t("doctor") }}</p>
        <vs-select
              v-if="user_role ==='secretary'"
              v-model="doctor"
              v-validate="'required'"
              autocomplete
              clearable
              class="w-full mb-4"
              name="doctor"
          >
          <vs-select-item :key="item.id" :value="item.id" :text="item.last_name" v-for="item in dentists" /></vs-select>
          
        <span v-if="user_role ==='secretary'" class="text-danger text-sm" v-show="errors.has('doctor')">{{$t("doctor_error")}}</span>

        
        <p class="pt-2" v-if="!checkbox_patient" >{{ $t("Nom") }}</p>
        <vs-input v-if="!checkbox_patient"
              name="last_name" 
              v-validate="'required'"
              class="w-full" 
              v-model="last_name">
        </vs-input>
        <span v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('last_name')">{{$t("last_name_error")}}</span>

        <p class="pt-2" v-if="!checkbox_patient" >{{ $t("Prénoms") }}</p>
        <vs-input v-if="!checkbox_patient"
              name="first_name" 
              v-validate="'required'"
              class="w-full" 
              v-model="first_name">
        </vs-input>
        <span v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('first_name')">{{$t("first_name_error")}}</span>
        
        <p class="pt-2" v-if="!checkbox_patient" >{{ $t("age") }}</p>
        <vs-input-number
                    v-if="!checkbox_patient"
                    :min="0" 
                    :max="120"
                    v-validate="'required'" 
                    name="age"
                    data-vv-validate-on="blur"
                    v-model="age" 
                    class="w-full"
                    />
          <span v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('age')">{{$t("age_error")}}</span>

          <p class="pt-2" v-if="checkbox_patient" >{{ $t("patient") }}</p>
          <vs-select
              v-if="checkbox_patient"
              v-model="patient"
              v-validate="'required'"
              autocomplete
              clearable
              class="w-full mb-4 "
              name="patient"
          >
          <vs-select-item :key="item.id" :value="item.id" :text="item.last_name" v-for="item in patients" />
          </vs-select>
          <span v-if="checkbox_patient" class="text-danger text-sm" v-show="errors.has('patient')">{{$t("patient_error")}}</span>

          <p class="pt-2" v-if="!checkbox_patient" >{{ $t("gender") }}</p>
          <v-select
           v-if="!checkbox_patient"
            v-validate="'required'"
            name="gender"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full" 
            v-model="gender"
            :options="genders">
          </v-select>
          <span v-if="!checkbox_patient" class="text-danger text-sm mb-4" v-show="errors.has('gender')">{{$t("gender_error")}}</span>

          <p class="pt-2" v-if="!checkbox_patient"> {{$t("Phone")}} </p>
          <vue-tel-input
              v-if="!checkbox_patient"
              v-model="phoneNumber"
              :enabledCountryCode="true"
              :enabledFlags="true"
              :validCharactersOnly="true"
              data-vv-validate-on="blur"
              v-validate="'required|min:8|max:25'"
              @validate="yourValidationMethod"
              name="phoneNumber"
              class="w-full mb-6"
          >
          </vue-tel-input>
          <span  v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{$t("phoneNumber_error")}}</span>
        
          <!-- <p class="pt-2" v-if="!checkbox_patient"> {{$t("Phone")}} </p>
          <vue-tel-input
              v-if="!checkbox_patient"
              v-model="phoneNumber"
              :enabledCountryCode="true"
              :enabledFlags="true"
              :validCharactersOnly="true"
              data-vv-validate-on="blur"
              v-validate="'required|min:8|max:25'"
              @validate="yourValidationMethod"
              name="phoneNumber"
              class="w-full mb-6"
          >
          </vue-tel-input>
          <span  v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{$t("phoneNumber_error")}}</span>
         -->
          <vs-textarea
          v-model="pattern"
           class="w-full pt-4" 
           rows="3"
           v-validate="'required'"
           name="pattern"
           label="Motif"  />
          <span class="text-danger text-sm" v-show="errors.has('pattern')">{{$t("pattern_error")}}</span>


         <div class="flex flex-wrap justify-between my-5">
          <vs-button v-if="checkbox_patient === false && user_role !=='secretary'" :disabled="!validFormAdd2Client" @click="addEventCheckDate(new Date(Date.now()))" color="primary" type="filled">Ajouter</vs-button>
          <vs-button v-if="checkbox_patient === false && user_role ==='secretary'" :disabled="!validFormAdd2ClientSercetary" @click="addEventCheckDate(new Date(Date.now()))" color="primary" type="filled">Ajouter </vs-button>
          
          <vs-button v-if="checkbox_patient === true && user_role !=='secretary'"  :disabled="!validFormAddClient" @click="addEventCheckDate(new Date(Date.now()))" color="primary" type="filled">Ajouter </vs-button>
          <vs-button v-if="checkbox_patient === true && user_role ==='secretary'"  :disabled="!validFormAddClientSercetary" @click="addEventCheckDate(new Date(Date.now()))" color="primary" type="filled">Ajouter </vs-button>

          <vs-button @click="CancelEvent" color="danger" type="filled">Annuler</vs-button>
        </div>
    </vs-popup>

    <vs-popup v-if="user_role!=='admin'"
        class="calendar-event-dialog"
        :title="this.$t('titre_Ajouter_rendevous')"
        :active.sync="activePromptAddEventClickdate">


        <vs-checkbox v-model="checkbox_patient" class="mb-3">
          Est ce un patient de l'hopital?
        </vs-checkbox>

        <small class="date-label">{{ $t("etat") }}</small>
        <v-select
            v-validate="'required'"
            name="etat"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full mt-2 " 
            v-model="urgent"
            :options="urgent_type">
          </v-select>
          <span class="text-danger text-sm pt-2" v-show="errors.has('etat')">{{$t("etat_error")}}</span>
  
        <div>
            <small class="date-label">{{ $t("appointementDate") }}</small>
            <flat-pickr 
             class="w-full pt-2"
             :config="testcaebdar" 
             v-validate="'required'"
             name="appointement"
             v-model="start" 
             placeholder="Date et heure" />
             
        </div>
        <span class="text-danger text-sm pt-2" v-show="errors.has('appointement')">{{$t("appointement_error")}}</span>
        <span class="text-danger text-sm pt-2" v-if="heure_check">{{$t("appointement_hours")}}</span>

        <p class="pt-2" v-if="user_role ==='secretary'"> {{ $t("doctor") }}</p>
        <vs-select
              v-if="user_role ==='secretary'"
              v-model="doctor"
              v-validate="'required'"
              autocomplete
              clearable
              class="w-full mb-4"
              name="doctor"
          >
          <vs-select-item :key="item.id" :value="item.id" :text="item.last_name" v-for="item in dentists" /></vs-select>
          
        <span v-if="user_role ==='secretary'" class="text-danger text-sm" v-show="errors.has('doctor')">{{$t("doctor_error")}}</span>

        
        <p class="pt-2" v-if="!checkbox_patient" >{{ $t("Nom") }}</p>
        <vs-input v-if="!checkbox_patient"
              name="last_name" 
              v-validate="'required'"
              class="w-full" 
              v-model="last_name">
        </vs-input>
        <span v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('last_name')">{{$t("last_name_error")}}</span>

        <p class="pt-2" v-if="!checkbox_patient" >{{ $t("Prénoms") }}</p>
        <vs-input v-if="!checkbox_patient"
              name="first_name" 
              v-validate="'required'"
              class="w-full" 
              v-model="first_name">
        </vs-input>
        <span v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('first_name')">{{$t("first_name_error")}}</span>
        
        <p class="pt-2" v-if="!checkbox_patient" >{{ $t("age") }}</p>
        <vs-input-number
                    v-if="!checkbox_patient"
                    :min="0" 
                    :max="120"
                    v-validate="'required'" 
                    name="age"
                    data-vv-validate-on="blur"
                    v-model="age" 
                    class="w-full"
                    />
          <span v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('age')">{{$t("age_error")}}</span>

          <p class="pt-2" v-if="checkbox_patient" >{{ $t("patient") }}</p>
          <vs-select
              v-if="checkbox_patient"
              v-model="patient"
              v-validate="'required'"
              autocomplete
              clearable
              class="w-full mb-4 "
              name="patient"
          >
          <vs-select-item :key="item.id" :value="item.id" :text="item.last_name" v-for="item in patients" />
          </vs-select>
          <span v-if="checkbox_patient" class="text-danger text-sm" v-show="errors.has('patient')">{{$t("patient_error")}}</span>

          <p class="pt-2" v-if="!checkbox_patient" >{{ $t("gender") }}</p>
          <v-select
           v-if="!checkbox_patient"
            v-validate="'required'"
            name="gender"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full" 
            v-model="gender"
            :options="genders">
          </v-select>
          <span v-if="!checkbox_patient" class="text-danger text-sm mb-4" v-show="errors.has('gender')">{{$t("gender_error")}}</span>

          <p class="pt-2" v-if="!checkbox_patient"> {{$t("Phone")}} </p>
          <vue-tel-input
              v-if="!checkbox_patient"
              v-model="phoneNumber"
              :enabledCountryCode="true"
              :enabledFlags="true"
              :validCharactersOnly="true"
              data-vv-validate-on="blur"
              v-validate="'required|min:8|max:25'"
              @validate="yourValidationMethod"
              name="phoneNumber"
              class="w-full mb-6"
          >
          </vue-tel-input>
          <span  v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{$t("phoneNumber_error")}}</span>
        
          <!-- <p class="pt-2" v-if="!checkbox_patient"> {{$t("Phone")}} </p>
          <vue-tel-input
              v-if="!checkbox_patient"
              v-model="phoneNumber"
              :enabledCountryCode="true"
              :enabledFlags="true"
              :validCharactersOnly="true"
              data-vv-validate-on="blur"
              v-validate="'required|min:8|max:25'"
              @validate="yourValidationMethod"
              name="phoneNumber"
              class="w-full mb-6"
          >
          </vue-tel-input>
          <span  v-if="!checkbox_patient" class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{$t("phoneNumber_error")}}</span>
         -->
          <vs-textarea
          v-model="pattern"
           class="w-full pt-4" 
           rows="3"
           v-validate="'required'"
           name="pattern"
           label="Motif"  />
          <span class="text-danger text-sm" v-show="errors.has('pattern')">{{$t("pattern_error")}}</span>


         <div class="flex flex-wrap justify-between my-5">
          <vs-button v-if="checkbox_patient === false && user_role !=='secretary'" :disabled="!validFormAdd2Client" @click="addEventCheckDate(new Date(Date.now()))" color="primary" type="filled">Ajouter</vs-button>
          <vs-button v-if="checkbox_patient === false && user_role ==='secretary'" :disabled="!validFormAdd2ClientSercetary" @click="addEventCheckDate(new Date(Date.now()))" color="primary" type="filled">Ajouter </vs-button>
          
          <vs-button v-if="checkbox_patient === true && user_role !=='secretary'"  :disabled="!validFormAddClient" @click="addEventCheckDate(new Date(Date.now()))" color="primary" type="filled">Ajouter </vs-button>
          <vs-button v-if="checkbox_patient === true && user_role ==='secretary'"  :disabled="!validFormAddClientSercetary" @click="addEventCheckDate(new Date(Date.now()))" color="primary" type="filled">Ajouter </vs-button>

          <vs-button @click="CancelEvent" color="danger" type="filled">Annuler</vs-button>
        </div>
    </vs-popup>
    <vs-popup v-if="user_role!=='admin'"
        class="calendar-event-dialog"
        :title="this.$t('titre_modifier_supprimer_redevous')"
        :active.sync="activePromptEditEventClient">

        <vs-checkbox disabled v-if="validated===true" v-model="validated" class="mb-3">
          Rendez-vous validé
        </vs-checkbox>
        <div>
        <small class="date-label">{{ $t("etat") }}</small>
        <v-select
            :disabled ="validated"
            v-validate="'required'"
            name="etat"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full mt-2 " 
            v-model="urgent"
            :options="urgent_type">
          </v-select>
          <span class="text-danger text-sm pt-2" v-show="errors.has('etat')">{{$t("etat_error")}}</span>
        </div>
        
          <small class="date-label">{{ $t("full_name") }}{{ " "}}{{'du patient'}}</small>
        <vs-input disabled name="event-name" v-validate="'required'" class="w-full"  v-model="title"></vs-input>
        
        <small class="date-label">{{$t("Phone")}}</small>
          <vue-tel-input
              disabled
              v-model="phoneNumber"
              :enabledCountryCode="true"
              :enabledFlags="true"
              :validCharactersOnly="true"
              data-vv-validate-on="blur"
              v-validate="'required|min:8|max:25'"
              @validate="yourValidationMethod"
              name="phoneNumber"
              class="w-full"
          >
          </vue-tel-input>
        
        <small class="date-label">{{ $t("doctor") }}</small>
        <vs-select
              v-model="doctor"
              disabled
              class="w-full mb-4"
              name="doctor"
          >
          <vs-select-item :key="itemid" :value="itemid.id" :text="itemid.last_name" v-for="itemid in dentists" /></vs-select>
          
        
        <div  class="my-4">
            <small v-if="((new Date(this.time_GMT))<(new Date(this.start)))" class="date-label">{{ $t("appointementDate") }}</small>
            <flat-pickr v-if="((new Date(this.time_GMT))<(new Date(this.start)))"
            disabled
            class="w-full pt-2"
             :config="testcaebdar" 
             v-validate="'required'"
             name="appointementDate"
             v-model="start" 
            placeholder="Date et heure" />
        </div>
        <span v-if="((new Date(this.time_GMT))<(new Date(this.start)))" class="text-danger text-sm pt-2" v-show="errors.has('appointementDate')">{{$t("appointement_error")}}</span>
        <span class="text-danger text-sm pt-2" v-if="heure_check">{{$t("appointement_hours")}}</span>

        
        <small v-if="((new Date(this.time_GMT))>(new Date(this.start)))" class="date-label">{{ $t("appointementDate") }}</small>
        <vs-input v-if="((new Date(this.time_GMT))>(new Date(this.start)))" 
        disabled 
        name="event-name" 
        v-validate="'required'"
        class="w-full mb-5"
        v-model="dateCheck2">
        </vs-input>
        
        
        <vs-textarea
           disabled
           v-model="pattern"
           class="w-full " 
           rows="3"
           v-validate="'required'"
           name="pattern"
           label="Motif"  />
        <span class="text-danger text-sm" v-show="errors.has('pattern')">{{$t("pattern_error")}}</span>
        
        <vs-textarea
          v-model="comment"
           class="w-full pt-4" 
           rows="3"
           name="comment"
           label="Commentaires"  />
<!--           <span class="text-danger text-sm" v-show="errors.has('comment')">{{$t("comment_error")}}</span>
 -->          
          <div class="flex flex-wrap justify-center mb-3">
          <vs-button v-if="validated === false && user_role !=='secretary'" class="ml-6" :disabled="!validForm1" @click="validate_appointment(new Date(Date.now()))" color="success" type="filled">Valider </vs-button>
          
          <vs-button class="ml-6" :disabled="!validForm1" @click="editEvent" color="primary" type="filled">Modifier </vs-button>

          <vs-button class="ml-6" :disabled="!validForm1" @click="removeEvent" color="danger" type="filled">Supprimer</vs-button>
        </div>
    </vs-popup>
    
    <vs-popup v-if="user_role!=='admin'"
         class="calendar-event-dialog"
        :title="this.$t('detail_rendez_vous')"
         button-cancel = "border"
        :active.sync="activePromptDetailEventClient">
        <vs-checkbox disabled v-if="validated===true" v-model="validated" class="mb-3">
          Rendez-vous validé
        </vs-checkbox>
        <vs-checkbox disabled v-if="validated===false" v-model="validated" class="mb-3">
          Rendez-vous non validé
        </vs-checkbox>
        <small class="date-label">{{ $t("etat") }}</small>
        <v-select
            disabled
            v-validate="'required'"
            name="etat"
            autocomplete
            label="name"
            :reduce="rep => rep.key"
            class="w-full mt-2 " 
            v-model="urgent"
            :options="urgent_type">
          </v-select>
        <small class="date-label">{{ $t("full_name") }}{{ " "}}{{'du patient'}}</small>
        <vs-input disabled name="event-name" v-validate="'required'" class="w-full"  v-model="title"></vs-input>
  
        <small class="date-label">{{$t("Phone")}}</small>
          <vue-tel-input
              disabled
              v-model="phoneNumber"
              :enabledCountryCode="true"
              :enabledFlags="true"
              :validCharactersOnly="true"
              data-vv-validate-on="blur"
              v-validate="'required|min:8|max:25'"
              @validate="yourValidationMethod"
              name="phoneNumber"
              class="w-full"
          >
          </vue-tel-input> 
        <small class="date-label">{{ $t("appointementDate") }}</small>
        <vs-input
        disabled 
        name="event-name" 
        v-validate="'required'"
        class="w-full mb-5"
        v-model="dateCheck2">
        </vs-input>
        
        
        <vs-textarea
           disabled
          v-model="pattern"
           class="w-full " 
           rows="3"
           v-validate="'required'"
           name="pattern"
           label="Motif"  />
        
        <vs-textarea
           disabled
           v-model="comment"
           class="w-full pt-4" 
           rows="3"
           v-validate="'required'"
           name="comment"
           label="Commentaires"  />

    </vs-popup>


  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import frLocale from '@fullcalendar/core/locales/fr'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import https from 'node-fetch'
import moment from 'moment'
const French = require('flatpickr/dist/l10n/fr.js').default.fr
const sound =  require('@/assets/audio/Facebook-SMS.mp3')
const url = 'https://worldtimeapi.org/api/timezone/Africa/Lome'
const requestOptions = {
  method: 'GET',
  header: {'Access-Control-Allow-Origin': 'GET'}
}

export default {
  components: {
    flatPickr,
    FullCalendar
  },
  data () {
    return {
      heure_check:false,
      filter_appointment:'',
      appointmentChoises: [
        {
          key: 'day',
          name: 'Jour'
        },
        
        {
          key: 'week',
          name: 'Semaine'
        }
      
      ],
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:s',
        locale: French,
        minDate: new Date(Date.now())
      },
      urgent_type: [
        {name: 'Urgent', key: true},
        {name: 'Non urgent', key: false}
        
      ],
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          listPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,listWeek'
        },
        initialView: 'dayGridMonth',
        events: [], // alternatively, use the `events` setting to fetch from a feed
        locale:frLocale,
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekNumbers: true,
        weekends: true,
        select: this.openAddNewEvent,
        eventClick: this.openEditEvent,
        eventsSet: this.handleEvents,
        slotMinTime: '06:00',
        slotMaxTime: '22:30'
      },
      currentEvents: [],
      count:0,
      count_old:'',
      count_test:'',
      check_count:true,
      dentists:[],
      dateCheck2:'',
      id: 0,
      time_GMT:'',
      urgent:'',
      validated:false,
      title: '',
      start: '',
      activePromptAddEvent: false,
      activePromptAddEventClickdate:false,
      activePromptEditEvent: false,
      activePromptEditEventClient: false,
      activePromptDetailEventClient: false,
      activePromptEditEventAdmin:false,
      phoneNumber:'',
      genders: [
        {name: 'Masculin', key: 'male'},
        {name: 'Féminin', key: 'female'}
      ],
      doctors: [],
      patients: [],
      comment: '',
      first_name:'',
      last_name:'',
      age:'',
      gender:'',
      patient:'',
      pattern:'',
      appointementDate: this.start,
      doctor:'',
      dateCheck:'',
      checkbox_patient:false,
      user_role : JSON.parse(localStorage.getItem('userInfo')).role.name,
      start_clik :'',
      checkhours : false
    }
  },
  watch: {
    filter_appointment (value) {
      if (value === 'day') {
        this.$router.push('/appointments_day/').catch(() => {})
      } else if (value === 'month') {
        this.$router.push('/appointments_month/').catch(() => {})

      }
    },
    start (value) {
      if (value) {
        let heure = 0
        let minute = 0
        heure = new Date(value).getHours() 
        minute = new Date(value).getMinutes()       
        if (heure >=  6 && heure <= 22) {
          if (heure === 22 && minute <= 0) {
            this.heure_check = false
          } else if (heure === 22 && minute > 0) {
            this.heure_check = true
          } else {
            this.heure_check = false
          }
          
        } else {
          this.heure_check = true
        }    

      }
    }
  },
  computed: {
    date_system () {
      return this.time_GMT <= (new Date(Date.now()))
    },
    testcaebdar () {
      return this.configdateTimePicker
    },
    
    validForm () {
      return this.pattern !== '' && this.start !== '' && this.urgent !== '' && this.heure_check === false
    },
    validForm1 () {
      return this.pattern !== '' && this.start !== '' && this.comment !== '' && this.urgent !== '' && this.heure_check === false
    },
    validFormAdd () {
      return this.pattern !== '' && this.start !== '' && this.doctor !== '' && this.patient !== '' && this.urgent !== '' && this.heure_check === false
    },
    validFormAddClient () {
      return this.pattern !== '' && this.start !== '' && this.patient !== '' && this.urgent !== ''  && this.heure_check === false
    },
    validFormAddClientSercetary () {
      return this.pattern !== '' && this.start !== '' && this.patient !== '' && this.urgent !== '' && this.heure_check === false
    },
    validFormAdd2 () {
      return this.pattern !== '' && this.start !== '' && this.doctor !== '' && this.first_name !== '' && this.last_name !== '' && this.age !== '' && this.gender !== '' && this.phoneNumber !== '' && this.urgent !== '' && this.heure_check === false
    },
    validFormAdd2Client () {
      return this.pattern !== '' && this.start !== '' && this.first_name !== '' && this.last_name !== '' && this.age !== '' && this.gender !== '' && this.phoneNumber !== '' && this.urgent !== '' && this.heure_check === false
    },
    validFormAdd2ClientSercetary () {
      return this.pattern !== '' && this.start !== '' && this.doctor !== '' && this.first_name !== '' && this.last_name !== '' && this.age !== '' && this.gender !== '' && this.phoneNumber !== '' && this.urgent !== '' && this.heure_check === false
    },
    labelColor () {
      return (label) => {
        if      (label === 'business') return 'success'
        else if (label === 'work')     return 'warning'
        else if (label === 'personal') return 'danger'
        else if (label === 'none')     return 'primary'
      }
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    yourValidationMethod ({ country }) {
      this.code = country.dialCode
    },
    addEventCheckDate (data) {
      const heure1 = (new Date(this.time_GMT))
      const heure2 = (new Date(data))

      if (heure1 <= heure2) {
        this.addEvent()
      } else {
        this.$vs.notify({
          color:'warning',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          time:15000,
          title:'ATTENTION!!!',
          text:'Date sytème modifiée',
          position:'top-center'
        })
      }
    },

    addEvent () {
      this.$vs.loading()
      const obj = this.checkbox_patient === false ? {
        first_name:this.first_name,
        last_name:this.last_name,
        urgent : this.urgent,
        age:this.age,
        gender:this.gender,
        phoneNumber:`+${this.code } ${  this.phoneNumber}`,
        pattern:this.pattern,
        appointementStartDate: this.start,
        comment:this.comment,
        doctor:this.doctor
      } : {
        urgent : this.urgent,
        patient:this.patient,
        pattern:this.pattern,
        appointementStartDate: this.start,
        comment:this.comment,
        doctor:this.doctor
      }

     
      const data = JSON.parse(JSON.stringify(obj))

      if (this.user_role !== 'admin' && this.user_role !== 'secretary') {
        data.doctor = JSON.parse(localStorage.getItem('userInfo')).id
      }
      this.activePromptAddEvent = false
      this.activePromptAddEventClickdate = false
      let url = 'appointments/'
      let methods = 'post'
      const message = {
        error: this.$t('save_error'),
        success: this.$t('appointement_save')
      }
      if (data.id) {
        url += `${data.id}/`
        methods = 'put'
        message.success = this.$t('appointement_update')
      }
  
      this.$http[methods](url, data)
        .then((response) => {
          window.eventTotal.getAllappointmentsCalandar()
          window.getPrendTaCom.success(message.success, response)
          this.clearFields()
          
        })
        .catch((error) => {
  
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            let libelle = ''
            if (item === 'first_name') {
              libelle = this.$t('Prénoms')
            }
            if (item === 'last_name') {
              libelle = this.$t('Nom')
            }
            if (item === 'age') {
              libelle = this.$t('age')
            }
            if (item === 'gender') {
              libelle = this.$t('gender')
            }
            if (item === 'patient') {
              libelle = this.$t('patient')
            }
            if (item === 'pattern') {
              libelle = this.$t('pattern')
            }
            if (item === 'appointementStartDate') {
              libelle = this.$t('appointementDate')
            }
            if (item === 'doctor') {
              libelle = this.$t('doctor')
            }

            if (item === 'phoneNumber') {
              libelle = this.$t('phoneNumber')
            }
  
            for (let j = 0; j < error.response.data[item].length; j++) {
              window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
            }
          }
  
          window.getPrendTaCom.error(message.error)
        })
      
        
    },
        
  
    CancelEvent () {
      this.clearFields()
      this.activePromptAddEvent = false
      this.activePromptAddEventClickdate = false
    },
    clearFields () {
      this.first_name = ''
      this.urgent = ''
      this.validated = ''
      this.patient = ''
      this.last_name = ''
      this.comment = ''
      this.phoneNumber = ''
      this.age = ''
      this.gender = ''
      this.pattern = ''
      this.appointementDate = ''
      this.start = ''
      this.doctor = ''
      this.id = 0
      this.checkbox_patient = false
    },
    promptAddNewEvent (data) {
      const heure4 = (new Date(this.time_GMT))
      const heure2 = (new Date(data))
      
      if (heure4 <= heure2) {
        this.addNewEventDialog()
      } else {
        this.$vs.notify({
          color:'warning',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          time:15000,
          title:'ATTENTION!!!',
          text:'Date sytème modifiée',
          position:'top-center'
        })
      }
      
    },
    promptAddNewEventClickdate () {
      this.addNewEventDialogClickdate()
    },
    addNewEventDialog () {
      this.clearFields()
      this.start = (new Date(Date.now()))
      this.activePromptAddEvent = true
    },
    addNewEventDialogClickdate () {
      this.clearFields()
      this.start = this.start_clik
      this.activePromptAddEventClickdate = true
    },
    openAddNewEvent (date) {
      const heure1 = (new Date(this.time_GMT))
    
      if ((moment(date.start, 'DD/MM/YYYY') >= moment(heure1.toLocaleDateString('fr'), 'DD/MM/YYYY'))) {
        this.start_clik = `${new Date(date.startStr).getUTCFullYear()}-${1 + new Date(date.startStr).getUTCMonth()}-${new Date(date.startStr).getUTCDate()} ${new Date(Date.now()).getHours()}:${new Date(Date.now()).getMinutes()}:${new Date(Date.now()).getSeconds()}`
        //this.start_clik = (new Date(date.startStr))
        this.promptAddNewEventClickdate()/* AVOIR */
      } else {
        /*  this.$vs.notify({
          color:'warning',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          time:15000,
          title:'ATTENTION!!!',
          text:'Date sytème modifiée',
          position:'top-center'
        }) */
      }
      
    },

    openEditEvent (event) {
      const user_roleCalendar = JSON.parse(localStorage.getItem('userInfo')).role.name
      const resultEven = event.event
      this.id = resultEven.id
      this.title = resultEven.extendedProps.full_name
      //this.heure = resultEven.heure
      this.start = resultEven.start
      this.dateCheck = resultEven.start
      this.dateCheck2 = resultEven.extendedProps.date_heure
      this.pattern = resultEven.extendedProps.pattern
      this.comment = resultEven.extendedProps.comment
      this.phoneNumber = resultEven.extendedProps.phoneNumber
      this.doctor = resultEven.extendedProps.doctor 
      this.urgent = resultEven.extendedProps.urgent
      this.validated = resultEven.extendedProps.validated
      if (user_roleCalendar === 'admin') {
        if ((new Date(this.dateCheck)) <= (new Date(this.time_GMT))) {
          this.activePromptEditEventAdmin = true

        } else {
          this.activePromptEditEvent = true

        }
      } else if (user_roleCalendar !== 'admin') {

        if ((new Date(this.dateCheck)) <= (new Date(this.time_GMT))) {
          
          this.activePromptDetailEventClient = true

        } else {
          this.activePromptEditEventClient = true

        }
        
      }
    },

    editEvent (date) {
      if ((new Date(date))<(new Date(this.time_GMT))) {
        this.activePromptEditEvent = false
        this.activePromptEditEventClient = false
        this.$vs.notify({
          color:'warning',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          time:15000,
          title:'ATTENTION!!!',
          text:'Erreur date système modifiée',
          position:'top-center'
        })

      } else {
        this.activePromptEditEvent = false
        this.activePromptEditEventClient = false

        const obj = { 
          id: this.id,
          urgent:this.urgent, 
          pattern:this.pattern,
          doctor:this.doctor.value,
          appointementStartDate: this.start,
          comment : this.comment
        }
        const data = JSON.parse(JSON.stringify(obj))
        this.$vs.dialog({
          type:'confirm',
          color: 'success',
          title: 'Confirmation',
          text: this.$t('confir_upd'),
          acceptText: this.$t('Confirmer'),
          cancelText: this.$t('Annuler'),
          accept: async () => {
            this.$vs.loading()
            this.$http.put(`appointments/${data.id}/`, data)
              .then(response => {
                window.eventTotal.getAllappointmentsCalandar()
                window.getPrendTaCom.success(this.$t('appointement_update'), response)
              })
              .catch((error) => {
                const clefs = Object.keys(error.response.data)
                for (let i = 0; i < clefs.length; i++) {
                  const item = clefs[i]
                  let libelle = ''
                  if (item === 'first_name') {
                    libelle = this.$t('Prénoms')
                  }
                  if (item === 'last_name') {
                    libelle = this.$t('Nom')
                  }
                  if (item === 'age') {
                    libelle = this.$t('age')
                  }
                  if (item === 'gender') {
                    libelle = this.$t('gender')
                  }
                  if (item === 'patient') {
                    libelle = this.$t('patient')
                  }
                  if (item === 'pattern') {
                    libelle = this.$t('pattern')
                  }
                  if (item === 'appointementStartDate') {
                    libelle = this.$t('appointementDate')
                  }
                  if (item === 'doctor') {
                    libelle = this.$t('doctor')
                  }

                  if (item === 'comment') {
                    libelle = this.$t('comment')
                  }
                  if (item === 'phoneNumber') {
                    libelle = this.$t('phoneNumber')
                  }


                  for (let j = 0; j < error.response.data[item].length; j++) {
                    window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
                  }
                }

                window.getPrendTaCom.error('Mise à jour échouée')
              })

          }
        })


      }
    },

    removeEvent (date) {

      if ((new Date(date))<(new Date(this.time_GMT))) {
        this.activePromptEditEvent = false
        this.activePromptEditEventClient = false
        this.$vs.notify({
          color:'warning',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          time:15000,
          title:'ATTENTION!!!',
          text:'Erreur date système modifiée',
          position:'top-center'
        })

      } else {
        this.activePromptEditEvent = false
        this.activePromptEditEventClient = false

        this.$vs.dialog({
          type:'confirm',
          color: 'danger',
          title: 'Confirmation',
          text: this.$t('enteteDelete'),
          acceptText: this.$t('Confirmer'),
          cancelText: this.$t('Annuler'),
          accept: async () => {
            this.$vs.loading()
            this.$http.delete(`appointments/${this.id}/`)
              .then(response => {
                window.eventTotal.getAllappointmentsCalandar()
                this.refrech()
                window.getPrendTaCom.success(this.$t('appointement_Suppression'), response)
              })
              .catch(() => {
                window.getPrendTaCom.error({ message: this.$t('Suppression')})
              })
          }
        })

      }
     

    },
    refrech () {
      this.$http.get('dashboard-dentist/')
        .then((response) => {
          this.count_old = response.data.appointments
          this.count = response.data.appointments
  
        })
    },
  
    refrech_old () {
      this.$http.get('dashboard-dentist/')
        .then((response) => {
          this.count_old = response.data.appointments
  
        })
    },
  
    validate_appointment (date) {
      if ((new Date(date))<(new Date(this.time_GMT))) {
        this.activePromptEditEvent = false
        this.activePromptEditEventClient = false
        this.$vs.notify({
          color:'warning',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          time:15000,
          title:'ATTENTION!!!',
          text:'Erreur date système modifiée',
          position:'top-center'
        })

      } else {
        this.activePromptEditEvent = false
        this.activePromptEditEventClient = false

        this.$vs.dialog({
          type:'confirm',
          color: 'success',
          title: 'Confirmation',
          text: this.$t('Confirmer_validation'),
          acceptText: this.$t('Confirmer'),
          cancelText: this.$t('Annuler'),
          accept: async () => {
            this.$vs.loading()
            this.$http.post('validate-appointment/', { appointment:this.id})
              .then(response => {
                this.getAllappointmentsCalandar() 
                window.getPrendTaCom.success(this.$t('validate_appointment_success'), response)
              })
              .catch(() => {
                window.getPrendTaCom.error({ message: this.$t('validate_appointment_error') })
              })
          }
        })
      }
  
    },
    
    getAllappointmentsCalandar () {
      this.$vs.loading()
      this.calendarOptions.events.length = 0
      let color = ''
      this.$http.get('appointments-by-month/')
        .then((response) => {
          const results = response.data
          this.count_old = response.data.count
          this.count = response.data.count
          results.forEach(event => {
            if (event.urgent === true && event.validated === true) {
              color = '#149106' //vert
            } else if (event.urgent === true && event.validated === false) {
              color = '#a30808'//rouge
            } else if (event.urgent === false && event.validated === true) {
              color = '#149106' //vert
            } else if (event.urgent === false && event.validated === false) {
              color = '' //bleu
            }
  
            this.calendarOptions.events.push({
              id: event.id,
              start: event.appointementStartDate,
              end:event.appointementEndDate,
              full_name:`${event.patient.first_name} ${event.patient.last_name}`,
              title: `${event.patient.last_name} ( ${new Date(event.appointementStartDate).getHours()}h:${new Date(event.appointementStartDate).getMinutes()}mn )`,
              date_heure:`${new Date(event.appointementStartDate).getUTCFullYear()}-${1 + new Date(event.appointementStartDate).getUTCMonth()}-${new Date(event.appointementStartDate).getUTCDate()} ${new Date(event.appointementStartDate).getHours()}:${new Date(event.appointementStartDate).getMinutes()}`,
              phoneNumber:event.patient.phoneNumber,
              backgroundColor: color,
              urgent:event.urgent,
              validated:event.validated,
              comment:event.comment,
              pattern:event.pattern,
              doctor:event.doctor.id
            })
  
          })
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
  
    },
  
    getAllappointmentsCalandarRefrech () {
      this.calendarOptions.events.length = 0
      let color = ''
      this.$http.get('appointments-by-month/')
        .then((response) => {
          const results = response.data
          this.count = response.data.count
          results.forEach(event => {
            if (event.urgent === true && event.validated === true) {
              color = '#149106' //vert
            } else if (event.urgent === true && event.validated === false) {
              color = '#a30808'//rouge
            } else if (event.urgent === false && event.validated === true) {
              color = '#149106' //vert
            } else if (event.urgent === false && event.validated === false) {
              color = '' //bleu
            }
  
            this.calendarOptions.events.push({
              id: event.id,
              start: event.appointementStartDate,
              end:event.appointementEndDate,
              full_name:`${event.patient.first_name} ${event.patient.last_name}`,
              title: `${event.patient.last_name} ( ${new Date(event.appointementStartDate).getHours()}h:${new Date(event.appointementStartDate).getMinutes()}mn )`,
              date_heure:`${new Date(event.appointementStartDate).getUTCFullYear()}-${1 + new Date(event.appointementStartDate).getUTCMonth()}-${new Date(event.appointementStartDate).getUTCDate()} ${new Date(event.appointementStartDate).getHours()}:${new Date(event.appointementStartDate).getMinutes()}`,
              phoneNumber:event.patient.phoneNumber,
              backgroundColor: color,
              urgent:event.urgent,
              validated:event.validated,
              comment:event.comment,
              pattern:event.pattern,
              doctor:event.doctor.id
            })
  
              
          })
        })
        .catch(() => {
          this.$vs.loading.close()
        })
  
    },
  
    async playnotification () {
      const audio = new Audio(sound)
      audio.play()
    },

    chat () {
      // const Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date()
      const s1 = document.createElement('script'), s0 = document.getElementsByTagName('script')[0]
      s1.async = true
      s1.src = 'https://embed.tawk.to/64da8f6ccc26a871b02f3b82/1h7qs0gqf'
      s1.charset = 'UTF-8'
      s1.setAttribute('crossorigin', '*')
      s0.parentNode.insertBefore(s1, s0)
    },
    

    dateCheckFirst () {
      https(url, requestOptions)
        .then((resp) => resp.json())
        .then((data) => {
          this.time_GMT = data.datetime 
        })
        .catch(function () {

        }) 
    }
      
  },
  created () {
    window.eventTotal = this 
    const user_roleCalendar = JSON.parse(localStorage.getItem('userInfo')).role.name
    this.dateCheckFirst()
    //this.chat()
    this.getAllappointmentsCalandar()
    this.$http.get('patients/')
      .then((response) => {
        this.patients = response.data
        this.$vs.loading.close()
      })
      .catch(() => {
        this.$vs.loading.close()
      })
    this.$http.get('dentists/')
      .then((response) => {
        this.dentists = response.data
        this.$vs.loading.close()
      })
      .catch(() => {
        this.$vs.loading.close()
      })
    if (user_roleCalendar !== 'admin') {
      this.counterInterval =  setInterval(
        function () {
          ///this.refrech_old()
          this.getAllappointmentsCalandarRefrech()
          //if (this.check_count) {
          if (this.count < this.count_old) {
            this.count_old = this.count
          }
          if (this.count> this.count_old) {
            this.count_old = this.count
            //this.check_count = false
            this.playnotification()
            this.$vs.notify({
              title: 'NOUVEAU MESSAGE',
              text: 'Vous avez un nouveau rendez-vous',
              color: 'warning',
              time:60000,
              iconPack: 'feather',
              icon:'icon-bell',
              position:'top-center'
            })
          }
  
          // }
           
        }.bind(this), 6000)
    }
    if (user_roleCalendar === 'admin') {
      this.counterInterval =  setInterval(
        function () {
          this.getAllappointmentsCalandarRefrech()
        }.bind(this), 60000)
    }
    document.title = 'MooziMed'
  
  },
  beforeDestroy () {
    this.$store.unregisterModule('calendar')
    if (this.user_role !== 'admin') {    
      clearInterval(this.counterInterval)
    }
    if (this.user_role === 'admin') {    
      clearInterval(this.counterInterval)
    }
  }
}
  
</script>
  
  
  <style>
   
   :root{
    --fc-event-bg-color: #4f769d;
    --fc-highlight-color: rgb(248 248 248 / 30%);
   }
   a:active, a:visited, a:hover, a {
      color: rgba(var(--vs-dark), 1);
  }
  .vx-card {
      background: #fff;
      border-radius: 0.5rem;
      box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
      padding: 10px;
  }
  </style>